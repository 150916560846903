import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { KeycloakService } from 'keycloak-angular';
import { selectUser, selectUserState } from '../../core/state/user/user.selectors';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private keycloakService: KeycloakService, private router: Router, private store: Store) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const isLoggedIn = await this.keycloakService.isLoggedIn();
    
    if (!isLoggedIn) {
      
      await this.keycloakService.login();
      return false; 
    }
    
    const token = this.keycloakService.getKeycloakInstance().tokenParsed;

    if(route.data.role){
      if(token?.resource_access?.[environment.clientId]?.roles.includes(route.data.role) === false){
        //TODO zrobić komponent unauthorized
        this.router.navigate(['/unauthorized']);
        return false;
      }
    }

    return true;
  }
}
